import _react3 from "react";
import _propTypes3 from "prop-types";
import _objectAssign3 from "object-assign";
import _object3 from "object.omit";
import _ElementRelativeCursorPosition3 from "./lib/ElementRelativeCursorPosition";
import _addEventListener3 from "./utils/addEventListener";
import _constants2 from "./constants";
import _noop3 from "./utils/noop";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = _react3;

var _react2 = _interopRequireDefault(_react);

var _propTypes = _propTypes3;

var _propTypes2 = _interopRequireDefault(_propTypes);

var _objectAssign = _objectAssign3;

var _objectAssign2 = _interopRequireDefault(_objectAssign);

var _object = _object3;

var _object2 = _interopRequireDefault(_object);

var _ElementRelativeCursorPosition = _ElementRelativeCursorPosition3;

var _ElementRelativeCursorPosition2 = _interopRequireDefault(_ElementRelativeCursorPosition);

var _addEventListener = _addEventListener3;

var _addEventListener2 = _interopRequireDefault(_addEventListener);

var _constants = _constants2;

var constants = _interopRequireWildcard(_constants);

var _noop = _noop3;

var _noop2 = _interopRequireDefault(_noop);

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _initialiseProps.call(_this);

    _this.state = {
      detectedEnvironment: {
        isMouseDetected: false,
        isTouchDetected: false
      },
      elementDimensions: {
        width: 0,
        height: 0
      },
      isActive: false,
      isPositionOutside: true,
      position: {
        x: 0,
        y: 0
      }
    };
    _this.shouldGuardAgainstMouseEmulationByDevices = false;
    _this.eventListeners = [];
    _this.timers = [];
    _this.elementOffset = {
      x: 0,
      y: 0
    };
    _this.onTouchStart = _this.onTouchStart.bind(_this);
    _this.onTouchMove = _this.onTouchMove.bind(_this);
    _this.onTouchEnd = _this.onTouchEnd.bind(_this);
    _this.onTouchCancel = _this.onTouchCancel.bind(_this);
    _this.onMouseEnter = _this.onMouseEnter.bind(_this);
    _this.onMouseMove = _this.onMouseMove.bind(_this);
    _this.onMouseLeave = _this.onMouseLeave.bind(_this);
    return _this;
  }

  _createClass(_class, [{
    key: "onTouchStart",
    value: function onTouchStart(e) {
      this.init();
      this.onTouchDetected();
      this.setShouldGuardAgainstMouseEmulationByDevices();
      var position = this.core.getCursorPosition(this.getTouchEvent(e));
      this.setPositionState(position);

      if (this.props.isActivatedOnTouch) {
        e.preventDefault();
        this.activate();
        return;
      }

      this.initPressEventCriteria(position);
      this.setPressEventTimer();
    }
  }, {
    key: "onTouchMove",
    value: function onTouchMove(e) {
      if (!this.isCoreReady) {
        return;
      }

      var position = this.core.getCursorPosition(this.getTouchEvent(e));

      if (!this.state.isActive) {
        this.setPressEventCriteria(position);
        return;
      }

      this.setPositionState(position);
      e.preventDefault();

      if (this.props.shouldStopTouchMovePropagation) {
        e.stopPropagation();
      }
    }
  }, {
    key: "onTouchEnd",
    value: function onTouchEnd() {
      this.deactivate();
      this.unsetShouldGuardAgainstMouseEmulationByDevices();
    }
  }, {
    key: "onTouchCancel",
    value: function onTouchCancel() {
      this.deactivate();
      this.unsetShouldGuardAgainstMouseEmulationByDevices();
    }
  }, {
    key: "onMouseEnter",
    value: function onMouseEnter(e) {
      if (this.shouldGuardAgainstMouseEmulationByDevices) {
        return;
      }

      this.init();
      this.onMouseDetected();
      this.setPositionState(this.core.getCursorPosition(e));
      this.clearActivationTimers();
      this.schedulActivation(this.props.hoverDelayInMs);
    }
  }, {
    key: "onMouseMove",
    value: function onMouseMove(e) {
      if (!this.isCoreReady) {
        return;
      }

      this.setPositionState(this.core.getCursorPosition(e));
    }
  }, {
    key: "onMouseLeave",
    value: function onMouseLeave() {
      this.clearActivationTimers();
      this.scheduleDeactivation(this.props.hoverOffDelayInMs);
      this.setState({
        isPositionOutside: true
      });
    }
  }, {
    key: "onTouchDetected",
    value: function onTouchDetected() {
      var environment = {
        isTouchDetected: true,
        isMouseDetected: false
      };
      this.setState({
        detectedEnvironment: environment
      });
      this.props.onDetectedEnvironmentChanged(environment);
    }
  }, {
    key: "onMouseDetected",
    value: function onMouseDetected() {
      var environment = {
        isTouchDetected: false,
        isMouseDetected: true
      };
      this.setState({
        detectedEnvironment: environment
      });
      this.props.onDetectedEnvironmentChanged(environment);
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.props.isEnabled) {
        this.enable();
      }
    }
  }, {
    key: "componentWillReceiveProps",
    value: function componentWillReceiveProps(_ref) {
      var willBeEnabled = _ref.isEnabled;
      var isEnabled = this.props.isEnabled;
      var isEnabledWillChange = isEnabled !== willBeEnabled;

      if (!isEnabledWillChange) {
        return;
      }

      if (willBeEnabled) {
        this.enable();
      } else {
        this.disable();
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.clearTimers();
      this.disable();
    }
  }, {
    key: "enable",
    value: function enable() {
      this.addEventListeners();
    }
  }, {
    key: "disable",
    value: function disable() {
      this.removeEventListeners();
    }
  }, {
    key: "init",
    value: function init() {
      this.core = new _ElementRelativeCursorPosition2.default(this.el);
      this.setElementDimensionsState(this.getElementDimensions(this.el));
    }
  }, {
    key: "reset",
    value: function reset() {
      var _core = this.core;
      _core = _core === undefined ? {} : _core;
      var lastMouseEvent = _core.lastEvent;
      this.init();

      if (!lastMouseEvent) {
        return;
      }

      this.setPositionState(this.core.getCursorPosition(lastMouseEvent));
    }
  }, {
    key: "activate",
    value: function activate() {
      this.setState({
        isActive: true
      });
      this.props.onActivationChanged({
        isActive: true
      });
    }
  }, {
    key: "deactivate",
    value: function deactivate() {
      var _this2 = this;

      this.clearTimer(constants.PRESS_EVENT_TIMER_NAME);
      this.setState({
        isActive: false
      }, function () {
        var _state = _this2.state,
            isPositionOutside = _state.isPositionOutside,
            position = _state.position;

        _this2.props.onPositionChanged({
          isPositionOutside: isPositionOutside,
          position: position
        });

        _this2.props.onActivationChanged({
          isActive: false
        });
      });
    }
  }, {
    key: "setPositionState",
    value: function setPositionState(position) {
      var isPositionOutside = this.getIsPositionOutside(position);
      this.setState({
        isPositionOutside: isPositionOutside,
        position: position
      }, this.onPositionChanged);
    }
  }, {
    key: "setElementDimensionsState",
    value: function setElementDimensionsState(dimensions) {
      this.setState({
        elementDimensions: dimensions
      });
    }
  }, {
    key: "schedulActivation",
    value: function schedulActivation(schedule) {
      var _this3 = this;

      var scheduleId = setTimeout(function () {
        _this3.activate();
      }, schedule);
      this.timers.push({
        id: scheduleId,
        name: constants.SET_ACTIVATION_TIMER_NAME
      });
    }
  }, {
    key: "scheduleDeactivation",
    value: function scheduleDeactivation(schedule) {
      var _this4 = this;

      var scheduleId = setTimeout(function () {
        _this4.deactivate();
      }, schedule);
      this.timers.push({
        id: scheduleId,
        name: constants.UNSET_ACTIVATION_TIMER_NAME
      });
    }
  }, {
    key: "clearActivationTimers",
    value: function clearActivationTimers() {
      this.clearTimer(constants.SET_ACTIVATION_TIMER_NAME);
      this.clearTimer(constants.UNSET_ACTIVATION_TIMER_NAME);
    }
  }, {
    key: "setPressEventTimer",
    value: function setPressEventTimer() {
      var _this5 = this;

      var _props = this.props,
          pressDuration = _props.pressDuration,
          pressMoveThreshold = _props.pressMoveThreshold;
      this.timers.push({
        name: constants.PRESS_EVENT_TIMER_NAME,
        id: setTimeout(function () {
          if (Math.abs(_this5.currentElTop - _this5.initialElTop) < pressMoveThreshold) {
            _this5.activate();
          }
        }, pressDuration)
      });
    }
  }, {
    key: "setPressEventCriteria",
    value: function setPressEventCriteria(position) {
      this.currentElTop = position.y;
    }
  }, {
    key: "initPressEventCriteria",
    value: function initPressEventCriteria(position) {
      var top = position.y;
      this.initialElTop = top;
      this.currentElTop = top;
    }
  }, {
    key: "setShouldGuardAgainstMouseEmulationByDevices",
    value: function setShouldGuardAgainstMouseEmulationByDevices() {
      this.shouldGuardAgainstMouseEmulationByDevices = true;
    }
  }, {
    key: "unsetShouldGuardAgainstMouseEmulationByDevices",
    value: function unsetShouldGuardAgainstMouseEmulationByDevices() {
      var _this6 = this;

      this.timers.push({
        name: constants.MOUSE_EMULATION_GUARD_TIMER_NAME,
        id: setTimeout(function () {
          _this6.shouldGuardAgainstMouseEmulationByDevices = false;
        }, 0)
      });
    }
  }, {
    key: "clearTimers",
    value: function clearTimers() {
      var timers = this.timers;

      while (timers.length) {
        var timer = timers.pop();
        clearTimeout(timer.id);
      }
    }
  }, {
    key: "clearTimer",
    value: function clearTimer(timerName) {
      this.timers.forEach(function (timer) {
        if (timer.name === timerName) {
          clearTimeout(timer.id);
        }
      });
    }
  }, {
    key: "getElementDimensions",
    value: function getElementDimensions(el) {
      var _el$getBoundingClient = el.getBoundingClientRect(),
          width = _el$getBoundingClient.width,
          height = _el$getBoundingClient.height;

      return {
        width: width,
        height: height
      };
    }
  }, {
    key: "getIsPositionOutside",
    value: function getIsPositionOutside(position) {
      var x = position.x,
          y = position.y;
      var _state$elementDimensi = this.state.elementDimensions,
          width = _state$elementDimensi.width,
          height = _state$elementDimensi.height;
      var isPositionOutside = x < 0 || y < 0 || x > width || y > height;
      return isPositionOutside;
    }
  }, {
    key: "getTouchEvent",
    value: function getTouchEvent(e) {
      return e.touches[0];
    }
  }, {
    key: "getIsReactComponent",
    value: function getIsReactComponent(reactElement) {
      return typeof reactElement.type === "function";
    }
  }, {
    key: "shouldDecorateChild",
    value: function shouldDecorateChild(child) {
      return !!child && this.getIsReactComponent(child) && this.props.shouldDecorateChildren;
    }
  }, {
    key: "decorateChild",
    value: function decorateChild(child, props) {
      return (0, _react.cloneElement)(child, props);
    }
  }, {
    key: "decorateChildren",
    value: function decorateChildren(children, props) {
      var _this7 = this;

      return _react.Children.map(children, function (child) {
        return _this7.shouldDecorateChild(child) ? _this7.decorateChild(child, props) : child;
      });
    }
  }, {
    key: "addEventListeners",
    value: function addEventListeners() {
      this.eventListeners.push((0, _addEventListener2.default)(this.el, "touchstart", this.onTouchStart, {
        passive: false
      }), (0, _addEventListener2.default)(this.el, "touchmove", this.onTouchMove, {
        passive: false
      }), (0, _addEventListener2.default)(this.el, "touchend", this.onTouchEnd), (0, _addEventListener2.default)(this.el, "touchcancel", this.onTouchCancel), (0, _addEventListener2.default)(this.el, "mouseenter", this.onMouseEnter), (0, _addEventListener2.default)(this.el, "mousemove", this.onMouseMove), (0, _addEventListener2.default)(this.el, "mouseleave", this.onMouseLeave));
    }
  }, {
    key: "removeEventListeners",
    value: function removeEventListeners() {
      while (this.eventListeners.length) {
        this.eventListeners.pop().removeEventListener();
      }
    }
  }, {
    key: "getPassThroughProps",
    value: function getPassThroughProps() {
      var ownPropNames = Object.keys(this.constructor.propTypes);
      return (0, _object2.default)(this.props, ownPropNames);
    }
  }, {
    key: "render",
    value: function render() {
      var _this8 = this;

      var _props2 = this.props,
          children = _props2.children,
          className = _props2.className,
          mapChildProps = _props2.mapChildProps,
          style = _props2.style;
      var props = (0, _objectAssign2.default)({}, mapChildProps(this.state), this.getPassThroughProps());
      return _react2.default.createElement("div", {
        className: className,
        ref: function ref(el) {
          return _this8.el = el;
        },
        style: (0, _objectAssign2.default)({}, style, {
          WebkitUserSelect: "none"
        })
      }, this.decorateChildren(children, props));
    }
  }, {
    key: "isCoreReady",
    get: function get() {
      return !!this.core;
    }
  }]);

  return _class;
}(_react2.default.Component);

_class.displayName = "ReactCursorPosition";
_class.propTypes = {
  children: _propTypes2.default.any,
  className: _propTypes2.default.string,
  hoverDelayInMs: _propTypes2.default.number,
  hoverOffDelayInMs: _propTypes2.default.number,
  isActivatedOnTouch: _propTypes2.default.bool,
  isEnabled: _propTypes2.default.bool,
  mapChildProps: _propTypes2.default.func,
  onActivationChanged: _propTypes2.default.func,
  onPositionChanged: _propTypes2.default.func,
  onDetectedEnvironmentChanged: _propTypes2.default.func,
  pressDuration: _propTypes2.default.number,
  pressMoveThreshold: _propTypes2.default.number,
  shouldDecorateChildren: _propTypes2.default.bool,
  shouldStopTouchMovePropagation: _propTypes2.default.bool,
  style: _propTypes2.default.object
};
_class.defaultProps = {
  isActivatedOnTouch: false,
  isEnabled: true,
  hoverDelayInMs: 0,
  hoverOffDelayInMs: 0,
  mapChildProps: function mapChildProps(props) {
    return props;
  },
  onActivationChanged: _noop2.default,
  onPositionChanged: _noop2.default,
  onDetectedEnvironmentChanged: _noop2.default,
  pressDuration: 500,
  pressMoveThreshold: 5,
  shouldDecorateChildren: true,
  shouldStopTouchMovePropagation: false
};

var _initialiseProps = function _initialiseProps() {
  var _this9 = this;

  this.onPositionChanged = function () {
    var onPositionChanged = _this9.props.onPositionChanged;
    onPositionChanged(_this9.state);
  };
};

exports.default = _class;
export default exports;
export const __esModule = exports.__esModule;